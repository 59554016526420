<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userProfile.chineseName }}
        </p>
        <span class="user-status">{{ userProfile.email }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userProfile.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userProfile.chineseName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item>
      <span>{{userProfile.chineseName}}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item v-for="item in userProfile.roles" @click="changeRole(item)">
      <feather-icon
        size="16"
        :icon="item.roleID === role.roleID ? 'CheckCircleIcon' : 'CircleIcon'"
        class="mr-50"
      />

      <span v-b-tooltip.hover.top="item.facilityName" class="user-dropdown-facility">{{item.facilityName? item.facilityName : '&nbsp;'}}</span>
      <span v-b-tooltip.hover.top="item.roleName" class="user-dropdown-role" :class="{'float-right': userProfile.isAdmin !== true}">{{item.roleName}}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

<!--    <b-dropdown-item-->
<!--      :to="{ path: 'profile'}"-->
<!--      link-class="d-flex align-items-center"-->
<!--    >-->
    <b-dropdown-item link-class="d-flex align-items-center" @click="editProfile">
      <span>{{ $t('user.edit_profile') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="changePassword">
      <span>{{ $t('user.change_password') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <span>{{$t('user.logout')}}</span>
    </b-dropdown-item>

    <modal
      :title="$t('user.edit_profile')"
      :visible="show_edit_profile"
      :confirm-fun="runEditProfile"
      :cancel-fun="hideEditProfile"
    >
      <modal-form
        ref="modal_edit_profile"
        :fields="edit_profile_form"
        :default="edit_profile_default"
      />
    </modal>

    <modal
      :title="$t('user.change_password')"
      :visible="show_change_password"
      :confirm-fun="runChangePassword"
      :cancel-fun="hideChangePassword"
    >
      <modal-form
        ref="modal_change_password"
        :fields="change_password_form"
      />
    </modal>

  </b-nav-item-dropdown>

</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BRow, BCol,BForm, BFormInput, BFormGroup, VBTooltip
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import common from "@/common";
import router from "@/router";
import Modal from "@/components/Modal";
import ModalForm from "@/components/ModalForm";
import store from '@/store'


export default {
  components: {
    ModalForm,
    Modal,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow, BCol,BForm, BFormInput, BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      avatarText,
      userProfile: common.getUserProfile(),
      role: common.getRole(),
      show_edit_profile: false,
      show_change_password: false,
      edit_profile_form: [
        {
          name: 'chineseName',
          label: common.getI18n('staff.chineseName'),
          type: 'text'
        },
        {
          name: 'englishName',
          label: common.getI18n('staff.englishName'),
          type: 'text'
        },
        {
          name: 'email',
          label: common.getI18n('staff.contactEmail'),
          type: 'text'
        },
        {
          name: 'phone',
          label: common.getI18n('staff.contactPhone'),
          type: 'text'
        },
      ],
      edit_profile_default: {
        chineseName: '',
        englishName: '',
        email: '',
        phone: '',
      },
      change_password_form: [
        {
          name: 'oldPassword',
          label: common.getI18n('staff.input_old_password'),
          type: 'password'
        },
        {
          name: 'newPassword',
          label: common.getI18n('staff.input_password'),
          type: 'password'
        },
        {
          name: 'newPasswordAgain',
          label: common.getI18n('staff.input_password_again'),
          type: 'password'
        },
      ],
    }
  },

  computed: {
    getProfile: function () {
      return store.state.app.userProfile
    }
  },

  methods: {
    changeRole: function (role) {
      if (role.roleID === this.role.roleID) return

      common.setRole(role)
      common.setPermissions({ facilityID: role.facilityID })
      common.syncSearchFields({})
      common.refreshMenu(role.facilityID).then(res => {
        common.showLoading()
        location.href = '/'
      })
    },
    logout: function () {
      const that = this
      common.showConfirm(common.getI18n('login.logout_confirm'), '', function () {
        that.runLogout()
      })
    },
    runLogout: function () {
      common.logout()
      // common.clearAccessToken()
      // common.clearUserProfile()
      // this.$router.push({ path: 'login' })
      // common.showLoading()
      // location.reload()
    },
    editProfile: function () {
      this.show_edit_profile = true

      this.edit_profile_default.chineseName = common.getUserProfile().chineseName
      this.edit_profile_default.englishName = common.getUserProfile().englishName
      this.edit_profile_default.email = common.getUserProfile().email
      this.edit_profile_default.phone = common.getUserProfile().phone
    },
    runEditProfile: function () {
      this.show_edit_profile = false

      const chinese_name = this.$refs.modal_edit_profile.modalData.chineseName
      const english_name = this.$refs.modal_edit_profile.modalData.englishName
      const email = this.$refs.modal_edit_profile.modalData.email
      const phone = this.$refs.modal_edit_profile.modalData.phone

      common.apiPostData('/staff/EditProfile', {
        chineseName: chinese_name,
        englishName: english_name,
        email: email,
        phone: phone
      }).then(res => {
        common.showToast(common.getI18n('user.edit_profile_success'))
        common.apiGetData('/staff/GetProfile').then(res => {
          common.setUserProfile(res.data.profile)
          this.userProfile = common.getUserProfile()
        })
      })
    },
    hideEditProfile: function () {
      this.show_edit_profile = false
    },
    changePassword: function () {
      this.show_change_password = true
    },
    runChangePassword: function () {
      const old_password = this.$refs.modal_change_password.modalData.oldPassword
      const new_password = this.$refs.modal_change_password.modalData.newPassword
      const new_password_again = this.$refs.modal_change_password.modalData.newPasswordAgain

      if (!old_password) {
        common.showError('OldPasswordRequired')
      }
      if (!new_password || !new_password_again) {
        common.showError('NewPasswordRequired')
      }

      if (new_password !== new_password_again) {
        common.showError('NewPasswordNotSame')
      }

      this.show_change_password = false

      const that = this
      common.apiPostData('/staff/ChangePassword', {oldPassword: old_password, newPassword: new_password}).then(res => {
        common.showAlert(common.getI18n('user.login_after_change_password'), '', function () {
          that.runLogout()
        })
      })

    },
    hideChangePassword: function () {
      this.show_change_password = false
    }

  },
}
</script>
