<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <div>
      <impersonate v-if="is_impersonated" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale v-if="show_change_i18n"/>
      <dark-Toggler class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import common from "@/common";
import Impersonate from "@core/layouts/components/app-navbar/components/impersonate";

export default {
  data() {
    return {
      show_change_i18n: common.config.show_change_i18n,
      is_impersonated: common.getImpersonated()
    }
  },
  components: {
    Impersonate,
    BLink,

    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
